.main-mobile-container {
  padding-top: 20px;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  height: 500px;
  flex-direction: column;
}
    
.mobile-container {
  margin-top: 50px;
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
  align-items: center;
  height: 425px;
  width: 125%;
  justify-content: center;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  background: #5865F2
}
    
.mobile-discord-join-container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
    }
    
.title-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 300px;
        font-size: 18px;
    }
    
.main-title-text {
        font-family: "DM Sans", sans-serif;
        color: white;
        margin: 0px;
    }
    
.mobile-lorem-ipsum-container {
        display: flex;
        padding-top: 10px
    }
    
.mobile-content-text {
        margin: 0 auto;
        width: 300px;
        font-family: "DM Sans", sans-serif;
        color: white;
        font-size: 16px;
    }
    
.discord-button {
        display: flex;
        margin-top: 20px;
        margin-bottom: 10px;
        gap: 8px;
        background-color: rgba(255, 255, 255, 0.897);
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: none;
        border-radius: 8px;
        width: 180px;
        height: 45px;
        cursor: pointer;
    }
    
.discord-button:focus {
        outline: none;
      }
      
.discord-button:active {
        box-shadow: inset 5px 5px 10px -3px rgba(0, 0, 0, 0.7);
      }
    
.discord-icon {
        color: #1e2124;
        font-size: 20px;
      }
    
.join-discord-text {
        font-family: "DM Sans", sans-serif;
        font-size: 16px;
        color: #1e2124
      }

@media screen and (min-width: 605px) {
        .mobile-container {
          display: none;
        }
}

@media screen and (max-width: 605px) {
  .main-mobile-container {
    padding: 0px;
    width: 100%;
  }

  .mobile-container {
    border-radius: 0px;
    margin-top: 0px;
  }

  .discord-button {
    margin-top: 40px;
  }
}

@media screen and (max-height: 420px) {
  .mobile-container {
    overflow-x: hidden;
    overflow-y: hidden;
  }
}


