.rightSlide-in {
    animation: slideInFromRight 1s ease-out forwards;
  }

@keyframes slideInFromRight {
    from {
      transform: translateX(100%);
      opacity: 0;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

.fade-in {
    animation: fadeIn 2s ease-out forwards;
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

.main-community-container {
    display: flex;
    flex-direction: row;
    gap: 100px;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 700px;
    background-color: #36393e;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
}


.community-text-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 400px;
    margin-right: 50px;
    margin-top: 40px;
    height: 400px;
    justify-content: flex-start;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    background: #5865F2;
    border-radius: 8px;
    gap: 30px
}

.community-title-text {
    font-family: "DM Sans", sans-serif;
    color: white;
    font-size: 34px;
}

.community-description-text {
    font-family: "DM Sans", sans-serif;
    color: white;
    font-size: 22px;
}

.community-image-container {
    display: flex;
    width: 45%;
    justify-content: center;
    align-items: center;
   
}

.trainer-image {
    max-width: 80%;
    height: auto;
    display: block;
}

 @media screen and (max-width: 1130px) {
    .community-text-container {
        width: 350px;
        height: 400px;
        justify-content: center;
        margin-top: 50px;
    }

    .community-title-text {
        font-size: 32px;
        margin: 0px;
    }

    .community-description-text {
        font-size: 20px;
    }
}

@media screen and (max-width: 1030px) {
    .community-text-container {
        width: 300px;
        height: 50%;
        margin-top: 80px;
        padding: 30px;
    }

    .community-title-text {
        font-size: 28px;
        margin: 0px;
    }

    .community-description-text {
        font-size: 16px;
    }
}

@media screen and (max-width: 990px) {
    .main-community-container {
        display: none;
    }
}