.fade-in {
  animation: fadeIn 2s ease-out forwards;
}

/* Hide the photo by default */
.mobile-combat-photo {
display: none;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.main-mobile-combat-container {
  background-color: #272A30;
  overflow-y: hidden;
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-bottom: 20px;
  height: 1050px;
  gap: 200px;
}

.mobile-combat-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 20px;
  height: 20%;
  width: 82%;
  gap: 20px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
  background: #5865F2;
  border-radius: 8px;
}

.mobile-combat-text {
  font-family: "DM Sans", sans-serif;
  color: white;
  font-size: 32px;
}

.mobile-combat-description-text {
  font-family: "DM Sans", sans-serif; 
  color: white;
  font-size: 20px;
}

.mobile-combat-video-container {
  max-width: 80%;
  height: auto;
  display: flex;
}

.mobile-combat-video {
max-width: 100%;
height: auto;
display: block;
}

@media screen and (min-width: 990px) {
  .main-mobile-combat-container {
    display: none;
  }

  .mobile-combat-container {
    display: none;
  }

  .mobile-combat-video-container {
    display: none;
  }
}

@media screen and (max-width: 744px) {
  .mobile-combat-container {
    justify-content: center;
    align-items: center;
    height: 20%;
    width: 90%;
    padding: 20px;
  }

  .mobile-combat-description-text {
    margin-bottom: 10px;
  }
}

@media screen and (max-width: 658px) {

  .mobile-combat-container {
    height: 40%;
    gap: 20px;
  }

  .mobile-combat-text {
    font-size: 28px;
    margin: 0px;
  }

  .mobile-combat-description-text {
    font-size: 18px;
  }
}

@media screen and (max-width: 658px) {
  .main-mobile-combat-container {
    justify-content: flex-start;
  }

  .mobile-combat-container {
    width: 100%;
    height: 50%;
    gap: 20px;
  }
}

@media screen and (max-width: 620px) {
.main-mobile-combat-container {
  height: 900px;
  padding-bottom: 0px;
}

.mobile-combat-container {
  border-radius: 0px;
  height: 100%;
  padding-left: 30px;
  padding-right: 30px;
  justify-content: center;
  align-items: center;
  
}
  
.mobile-combat-video {
  display: none;
}
.mobile-combat-video-container {
  display: none;
}

.mobile-combat-photo {
  display: block;
  width: 100%;
  max-width: 100vw;
  height: auto;
}
}