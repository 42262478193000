.trapezoidal-horizontal {
    position: absolute;
    top: 611px;
    width: 100%;
    height: 80px;
  }

  .right-angle-triangle {
    position: absolute;
    width: 100%;
    height: 60px;
    bottom: 640px;
    z-index: 2;
  }
  

@media screen and (min-width: 1707px) {
  .trapezoidal-horizontal {
    display: none;
  }

  .right-angle-triangle {
    display: none;
  }
}