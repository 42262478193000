*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  width: 100%;
  overflow-x: hidden;
}
.app-container {
  background: #272A30;
  overflow-x: hidden;
  overflow-y: hidden;
}

.relative-container {
  background-color: #36393e;
  display: flex;
  position: relative;
  width: 100%;
  height: 700px;
  justify-content: center;
  overflow-y: hidden;
}

@media screen and (max-width: 987px) {
  .main-container {
    height: 750px;
  }

  .relative-container {
    height: 750px;
  }
}

@media screen and (max-width: 620px) {
  .relative-container {
    background-color: #282b30;
    overflow-x: hidden;
    height: 700px;
  }

  .main-container {
    height: 700px;
  }
}
