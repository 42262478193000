.giph-container {
    position: absolute;
    z-index: 3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 800px;
    right: 100px; 
    bottom: 1px;
    overflow-y: hidden;
    overflow-x: hidden;
}

.moki-gif {
    overflow-y: hidden;
    overflow-x: hidden;
    width: 800px;
    height: auto;
}

@media screen and (max-width: 1115px) {
    .moki-gif {
        width: 700px;
        height: auto;
    }
}

@media screen and (max-width: 1074px) {
    .moki-gif {
        position: relative;
        width: 570px;
        height: auto;
        top: 40px;
    }
}

@media screen and (max-width: 1050px) {
    .moki-gif {
        position: relative;
        top: 40px;
    }
}

@media screen and (max-width: 990px) {
    .moki-gif {
        position: relative;
        top: 80px;
        width: 500px;
        height: auto;
    }
}

@media screen and (max-width: 900px) {
    .moki-gif {
        position: relative;
        top: 100px;
        width: 400px;
        height: auto;
    }
}

@media screen and (max-width: 817px) {
    .moki-gif {
        position: relative;
        top: 170px;
        width: 350px;
        height: auto;
    }
}

@media screen and (max-width: 778px) {
    .moki-gif {
        position: relative;
        top: 180px;
        width: 300px;
        height: auto;
    }
}

@media screen and (max-width: 730px) {
    .moki-gif {
        position: relative;
        top: 150px;
        left: 20px;
        width: 250px;
        height: auto;
    }
}

@media screen and (max-width: 660px) {
    .moki-gif {
        position: relative;
        top: 170px;
        left: 20px;
        width: 250px;
        height: auto;
    }
}

@media screen and (max-width: 650px) {
    .moki-gif {
        position: relative;
        top: 180px;
        width: 250px;
        height: auto;
    }
}

@media screen and (max-width: 614px) {
    .moki-gif {
        position: relative;
        top: 180px;
        width: 250px;
        height: auto;
    }
}

@media screen and (max-width: 606px) {
    .giph-container {
        display: none;
    }
}