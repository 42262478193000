.slide-in {
    animation: slideInFromLeft 1s ease-out forwards;
}

@keyframes slideInFromLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}


.fade-in {
    animation: fadeIn 3s ease-out forwards;
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}

.main-combat-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 700px;
    background-color: #272A30;
    overflow-y: hidden;
    position: relative;
}

.main-combat-container::before {
    content: "";
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 30px;
    /* background-image: url('data:image/svg+xml;base64,CjxzdmcgeG1sbnM9J2h0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnJyB3aWR0aD0nMTAwJScgaGVpZ2h0PScxMDAlJyB2aWV3Qm94PScwIDAgMTIwMCAxMjAnIHByZXNlcnZlQXNwZWN0UmF0aW89J25vbmUnPgogICAgPHBhdGggZD0nTTAsMFY0Ni4yOWM0Ny44NS0xNS41OSwxMDMtMTYsMTUwLDUuNzEsNzAuNDUsMzIuNzcsMTIyLjgsMS41NCwxOTYtMjcuNDEsNTkuMjQtMjMuNDYsMTE2LjQxLTE1Ljg4LDE3NSwxMS40Miw3MC40NSwzMi43NywxMzcsMzkuMjcsMjAzLDUuNzEsNzYuNzYtMzksMTUyLTIxLDIyNSw1LjcxLDU0LjA3LDE5Ljg2LDEwOS42NiwyNi41NywxNzEsNS43MSw4OC4xMS0zMCwxNzguODktMjgsMjcwLTUuNzFWMFonIGZpbGw9JyMyODJiMzAnIG9wYWNpdHk9Jy4yNSc+PC9wYXRoPgogICAgPHBhdGggZD0nTTAsMFYxNS40M0M0Ny44NSw0LjQ1LDEwMyw1LjE0LDE1MCwyMi44NmM3MC40NSwyNi44NiwxMjIuOCwzLjIsMTk2LTIyLjU3LDU5LjI0LTE5LjM1LDExNi40MS0xMy4xLDE3NSw5LjQzLDcwLjQ1LDI3LjI5LDEzNywzMi40MywyMDMsNC43MSw3Ni43Ni0zMi4xMSwxNTItMTcuMjksMjI1LDQuNzEsNTQuMDcsMTYuNDMsMTA5LjY2LDIxLjk0LDE3MSw0LjcxLDg4LjExLTI0LjY3LDE3OC44OS0yMy4xNCwyNzAtNC43MVYwWicgZmlsbD0nIzQyNDU0OScgb3BhY2l0eT0nLjUnPjwvcGF0aD4KICAgIDxwYXRoIGQ9J00wLDBWNS43MUM0Ny44NS01LjI3LDEwMy00LjU4LDE1MCwxMy4xNGM3MC40NSwyMS40MywxMjIuOC00LjU4LDE5Ni0xOC44Niw1OS4yNC0xMS42NCwxMTYuNDEtOC4wNiwxNzUsNy44Niw3MC40NSwxOS4yOSwxMzcsMjAuNTcsMjAzLDAsNzYuNzYtMjMuNzEsMTUyLTEzLjU3LDIyNSwwLDU0LjA3LDEwLDEwOS42NiwxMy43MSwxNzEsMCw4OC4xMS0yMCwxNzguODktMTguNTcsMjcwLTBWMFonIGZpbGw9JyMzNjM5M2UnPjwvcGF0aD4KPC9zdmc+Cg=='); */
    background-repeat: repeat-x;
}


.combat-text-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 400px;
    margin-right: 50px;
    margin-top: 10px;
    height: 400px;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    background: #5865F2;
    border-radius: 8px;
    gap: 30px;
}

.combat-title-text {
    font-family: "DM Sans", sans-serif;
    color: white;
    font-size: 34px;
}

.combat-description-text {
    font-family: "DM Sans", sans-serif;
    color: white;
    font-size: 22px;
}

.combat-image-container {
    display: flex;
    width: 50%;
    justify-content: center;
    align-items: center;
}

.combat-image {
    max-width: 100%;
    height: auto;
    display: block;
}

 @media screen and (max-width: 1130px) {
    .combat-text-container {
        width: 350px;
        height: 400px;
        justify-content: center;
        margin-top: 50px;
    }

    .combat-title-text {
        font-size: 32px;
        margin: 0px;
    }

    .combat-description-text {
        font-size: 20px;
    }
}

@media screen and (max-width: 1030px) {
    .combat-text-container {
        width: 300px;
        height: 50%;
        margin-top: 80px;
        padding: 30px;
    }

    .combat-title-text {
        font-size: 28px;
        margin: 0px;
    }

    .combat-description-text {
        font-size: 16px;
    }
}

@media screen and (max-width: 990px) {
    .main-combat-container {
        display: none;
    }
}