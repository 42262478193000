.fade-in {
    animation: fadeIn 2s ease-out forwards;
}

@keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
}
  

.mobile-trainer-image2 {
    display: none;
}

.mobile-main-community-container {
    display: flex;
    flex-direction: column;
    gap: 200px;
    justify-content: center;
    align-items: center;
    align-self: center;
    width: 100%;
    height: 1050px;
    background-color: #36393e;
    position: relative;
    overflow-x: hidden;
    overflow-y: hidden;
    padding-top: 20px;
}

.mobile-community-text-container {
    padding: 20px;
    display: flex;
    flex-direction: column;
    width: 82%;
    height: 20%;
    justify-content: center;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    background: #5865F2;
    border-radius: 8px;
    gap: 20px
}

.mobile-community-image-container {
    display: flex;
    width: 45%;
    justify-content: center;
    align-items: center;
   
}

.mobile-trainer-image {
    max-width: 80%;
    height: auto;
    display: block;
}

.mobile-community-title-text {
    font-family: "DM Sans", sans-serif;
    color: white;
    font-size: 34px;
}

.mobile-community-description-text {
    font-family: "DM Sans", sans-serif;
    color: white;
    font-size: 20px;
}

@media screen and (min-width: 990px) {
    .mobile-main-community-container {
        display: none;
    }

    .mobile-community-image-container {
        display: none;
    }

    .mobile-trainer-image {
        display: none;
    }
}

@media screen and (max-width: 744px) {
    .mobile-community-text-container {
        height: 20%;
        padding: 20px;
        width: 90%;
    }
}

@media screen and (max-width: 658px) {
    .mobile-main-community-container {
        justify-content: flex-end;
        padding-bottom: 10px;
    }
    .mobile-community-text-container {
        width: 100%;
        height: 30%;
    }

    .mobile-community-title-text {
        font-size: 28px;
        margin: 0px;
    }

    .mobile-community-description-text {
        font-size: 18px;
    }

    .mobile-trainer-image {
        width: 100%;
        height: auto;
    }
  }

  @media screen and (max-width: 620px) {
    .mobile-main-community-container {
        justify-content: flex-end;
        padding-top: 200px;
        height: 1300px;
        padding-bottom: 0px;
    }

    .mobile-community-text-container {
        height: 100%;
        border-radius: 0px;
    }
    .mobile-community-image-container {
        display: none;
    }

    .mobile-trainer-image {
        display: none;
    }

    .mobile-trainer-image2 {
        display: block;
        width: 70%;
        height: auto;
      }
  }


  

