.landingstart-container {
    position: relative;
    overflow-y: hidden;
    margin-top: 110px;
    padding-left: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 425px;
    width: 80%;
    justify-content: flex-start;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    background: #5865F2
}

/*  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;  */

.discord-join-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 40%;
}

.title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 300px;
    font-size: 18px;
}

.main-title-text {
    font-family: "DM Sans", sans-serif;
    color: white;
    margin: 0px;
}

.lorem-ipsum-container {
    display: flex;
    padding-top: 10px
}

.content-text {
    margin: 0 auto;
    width: 300px;
    font-family: "DM Sans", sans-serif;
    color: white;
    font-size: 16px;
}

.discord-button {
    display: flex;
    margin-top: 20px;
    gap: 8px;
    background-color: rgba(255, 255, 255, 0.897);
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 8px;
    width: 180px;
    height: 45px;
    cursor: pointer;
}

.discord-button:focus {
    outline: none;
  }
  
  .discord-button:active {
    box-shadow: inset 5px 5px 10px -3px rgba(0, 0, 0, 0.7);
  }

  .discord-icon {
    color: #1e2124;
    font-size: 20px;
  }

  .join-discord-text {
    font-family: "DM Sans", sans-serif;
    font-size: 16px;
    color: #1e2124
  }

  @media screen and (max-width: 745px) {
    .discord-join-container {
        width: 50%;
    }
}

  @media screen and (max-width: 605px) {
    .landingstart-container {
        display: none;
    }
}